export default {
  blogerName: 'SPLASH',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@splashgames1791',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCGBdU6lyix1Uyhv6mErW-hg',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/splash_games1?igsh=MWVzbGFlYXk1ZTN5',
    },
    {
      name: 'telegram',
      url: 'https://t.me/+7zOLxPnzWb03ZjMx'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/c0e456cf6',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c53cf06db',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c3d230480',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c3ec86ce1',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c32408ae0',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c05af7f4d',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c5e63ab2e',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c12f9cdae',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Cadastre-se com um código promocional <strong>SPLASH</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'SPLASH',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
